<template>
  <div class='EmptyIcon'>
    <svg width="60px" height="60px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" class="si-glyph si-glyph-basket-error">
      <title>624</title>
      <defs>
      </defs>
      <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g transform="translate(0.000000, 1.000000)" fill="#434343">
          <path d="M11.504,12.524 L10.938,11.957 C10.84,12.129 10.677,12.25 10.483,12.25 L10.462,12.25 C10.16,12.25 9.917,11.977 9.917,11.639 L9.917,7.483 C9.917,7.145 10.16,6.872 10.462,6.872 L10.483,6.872 C10.784,6.872 11.029,7.145 11.029,7.483 L11.029,10.024 L12.02,9.033 L13.245,10.26 L13.479,5.918 L14.245,5.918 C14.645,5.918 14.97,4.757 14.97,4.757 C14.97,4.387 14.646,4.085 14.245,4.085 L12.317,4.085 L11.282,0.356 C11.131,0.039 10.756,-0.089 10.444,0.07 L10.373,0.106 C10.062,0.266 9.933,0.652 10.084,0.969 L10.825,4.085 L4.171,4.085 L4.939,1.001 C5.093,0.687 4.966,0.299 4.658,0.136 L4.588,0.1 C4.277,-0.062 3.9,0.062 3.746,0.377 L2.673,4.085 L0.783,4.085 C0.383,4.085 0.058,4.387 0.058,4.757 C0.058,4.757 0.382,5.918 0.783,5.918 L1.419,5.918 L1.868,12.653 C1.868,12.653 1.92,13.962 3.981,13.962 L10.07,13.962 L11.504,12.524 L11.504,12.524 Z M11.938,4.969 L13.032,4.969 L13.032,6.063 L11.938,6.063 L11.938,4.969 L11.938,4.969 Z M3.031,6.031 L1.969,6.031 L1.969,4.969 L3.031,4.969 L3.031,6.031 L3.031,6.031 Z M5.062,11.742 C5.062,12.092 4.811,12.375 4.503,12.375 L4.48,12.375 C4.169,12.375 3.919,12.092 3.919,11.742 L3.919,7.413 C3.919,7.063 4.169,6.781 4.48,6.781 L4.503,6.781 C4.811,6.781 5.062,7.063 5.062,7.413 L5.062,11.742 L5.062,11.742 Z M8.102,11.547 C8.102,11.881 7.842,12.152 7.524,12.152 L7.499,12.152 C7.178,12.152 6.919,11.881 6.919,11.547 L6.919,7.448 C6.919,7.114 7.178,6.844 7.499,6.844 L7.524,6.844 C7.842,6.844 8.102,7.115 8.102,7.448 L8.102,11.547 L8.102,11.547 Z" class="si-glyph-fill">
          </path>
          <path d="M15.969,14.281 L14.17,12.48 L15.95,10.7 L15.283,10.037 L13.504,11.817 L11.734,10.047 L11.027,10.755 L12.796,12.525 L11.052,14.271 L11.716,14.934 L13.461,13.189 L15.261,14.989 L15.969,14.281 Z" class="si-glyph-fill">
          </path>
        </g>
      </g>
    </svg>
  </div>
</template>

<script>
export default {
  name: 'EmptyIcon'
}
</script>

<style scoped>
.EmptyIcon {
}
</style>
