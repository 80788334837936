<template>
  <div class='EnbFooter'>
  </div>
</template>

<script>
export default {
  name: 'EnbFooter'
}
</script>

<style scoped>
.EnbFooter {
}
</style>
