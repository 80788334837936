import EnbProdutos from '@/components/Theme03/Produtos/EnbProdutos'

export default [
  {
    path: '/loja',
    name: 'Loja',
    component: EnbProdutos
  },
  {
    path: '/',
    name: 'Produtos',
    component: EnbProdutos
  }
]
