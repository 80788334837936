<template>
  <div class='EnbProdutos'>
  </div>
</template>

<script>
export default {
  name: 'EnbProdutos'
}
</script>

<style scoped>
.EnbProdutos {
}
</style>
