<template>
  <div class='LinkProfile02'>
    <div>
      login
    </div>
  </div>
</template>

<script>
export default {
  name: 'LinkProfile02'
}
</script>

<style scoped>
.LinkProfile02 {
  width: 6%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: end;
  color: #666666;
  cursor: pointer;
}
</style>
