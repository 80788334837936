<template>
  <div class='VarHeader'>
    <div style="width: 60%; text-align: left">
      Variação
    </div>
    <div style="width: 10%">
      Quantidade
    </div>
    <div style="width: 20%">
      Atualizar
    </div>
    <div style="width: 10%">
      Ações
    </div>
  </div>
</template>

<script>
export default {
  name: 'VarHeader'
}
</script>

<style scoped>
.VarHeader {
  display: flex;
  padding: 1%;
}
</style>
