<template>
  <div class='CloseIcon'>
    <svg width="18px" height="18px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M19.207 6.207a1 1 0 0 0-1.414-1.414L12 10.586 6.207 4.793a1 1 0 0 0-1.414 1.414L10.586 12l-5.793 5.793a1 1 0 1 0 1.414 1.414L12 13.414l5.793 5.793a1 1 0 0 0 1.414-1.414L13.414 12l5.793-5.793z" :fill="color"/>
    </svg>
  </div>
</template>

<script>
export default {
  name: 'CloseIcon',
  props: {
    color: {
      type: String,
      default: '#000000'
    }
  }
}
</script>

<style scoped>
.CloseIcon {
  cursor: pointer;
}
</style>
