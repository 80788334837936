<template>
  <div class='CoconutIcon'>
    <svg fill="#000000" height="20px" width="20px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 512 512" xml:space="preserve">
      <g>
<g>
<path d="M470.034,412.34c-11.569-21.153-33.341-34.287-58.19-34.287c-24.865,0-46.652,13.135-58.224,34.287
	c-24.864,3.653-41.967,24.991-41.967,49.107c0,5.853,1.018,11.47,2.872,16.696H267.13V246.867l79.775,79.775
	c6.52,6.52,17.091,6.52,23.611,0c32.299-32.299,22.41-91.955-18.927-136.777h65.802c9.22,0,16.696-7.475,16.696-16.696
	c0-45.619-49.052-80.78-109.864-83.322l46.54-46.54c6.52-6.519,6.52-17.091,0-23.611c-33.933-33.936-97.017-21.039-141.668,23.612
	c-4.45,4.45-8.428,9.311-11.914,14.475c-3.474-5.14-7.458-9.991-11.943-14.476C160.604-1.326,97.516-14.249,63.57,19.696
	c-6.52,6.519-6.52,17.091,0,23.611l46.534,46.534C50.042,92.246,0,126.838,0,173.17c0,9.22,7.475,16.696,16.696,16.696h65.809
	c-40.762,44.161-51.7,104.011-18.934,136.777c6.515,6.515,17.092,6.52,23.611,0l79.775-79.775v231.276H116.87
	c-9.22,0-16.696,7.475-16.696,16.696c0,9.22,7.475,16.696,16.696,16.696c15.291,0,327.91,0,345.043,0
	c27.618,0,50.087-22.469,50.087-50.087C512,437.445,495.004,416.009,470.034,412.34z M395.645,156.474h-87.137
	c-2.654-1.391-5.33-2.691-8.028-3.895c0.329-9.974-2.239-19.311-7.067-27.357c7.651-1.412,15.639-2.138,23.805-2.138
	C357.5,123.083,385.412,139.989,395.645,156.474z M252.706,66.918c25.138-25.138,57.218-37.2,79.13-31.906l-61.574,61.574
	c-4.447,1.374-8.775,2.951-12.968,4.718c-7.877-1.083-15.613-0.307-22.927,2.177C236.307,89.866,242.745,76.882,252.706,66.918z
	M102.497,35.012c21.909-5.29,53.994,6.771,79.128,31.905c10.015,10.017,16.283,22.774,18.134,36.577
	c-7.162-2.44-14.908-3.299-22.967-2.19c-4.079-1.72-8.288-3.257-12.607-4.604L102.497,35.012z M38.354,156.474
	c11.749-19.234,42.965-33.391,78.515-33.391c8.165,0,16.154,0.726,23.805,2.138c-4.831,8.052-7.395,17.391-7.067,27.357
	c-2.698,1.203-5.376,2.503-8.03,3.896H38.354z M78.886,287.716c-5.294-21.91,6.77-53.993,31.908-79.131
	c10.208-10.207,22.113-18.912,35.042-24.892c5.882,6.776,13.545,11.96,22.259,14.816L78.886,287.716z M183.652,167.604
	c-9.206,0-16.696-7.49-16.696-16.696c0-9.206,7.49-16.696,16.696-16.696c9.206,0,16.696,7.49,16.696,16.696
	C200.348,160.115,192.858,167.604,183.652,167.604z M233.739,478.143h-33.391v-54.539h33.391V478.143z M233.739,390.213h-33.391
	v-33.391h33.391V390.213z M233.739,323.43h-33.391v-66.872c5.226,1.853,10.843,2.872,16.696,2.872
	c5.852,0,11.47-1.018,16.696-2.872V323.43z M217.043,226.039c-9.206,0-16.696-7.49-16.696-16.696s7.49-16.696,16.696-16.696
	c9.206,0,16.696,7.49,16.696,16.696S226.249,226.039,217.043,226.039z M250.435,167.604c-9.206,0-16.696-7.49-16.696-16.696
	c0-9.206,7.49-16.696,16.696-16.696c9.206,0,16.696,7.49,16.696,16.696C267.13,160.115,259.641,167.604,250.435,167.604z
	M288.253,183.693c12.96,5.996,24.863,14.714,35.042,24.892c28.528,28.531,36.252,60.216,31.845,79.068l-89.146-89.145
	C274.708,195.653,282.371,190.47,288.253,183.693z M461.913,478.143H361.738c-9.206,0-16.695-7.49-16.695-16.696
	c0-8.633,6.517-17.215,18.091-16.179c7.659,0.678,14.801-3.952,17.288-11.229c5.694-16.66,19.965-22.595,31.42-22.595
	c11.439,0,25.691,5.935,31.386,22.595c2.487,7.278,9.628,11.903,17.288,11.229c11.555-1.028,18.091,7.521,18.091,16.179
	C478.609,470.654,471.119,478.143,461.913,478.143z"/>
      </g>
      </g>
    </svg>
  </div>
</template>

<script>
export default {
  name: 'CoconutIcon'
}
</script>

<style scoped>
.CoconutIcon {
}
</style>
