<template>
  <div class='StatusIcon' @mouseenter="textShow=true" @mouseleave="textShow=false">
    <svg :width="options.width" :height="options.height" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
      <path fill="#000000" fill-rule="evenodd" d="M14.1026,4.43011 C14.6139,4.22135 15.1977,4.46661 15.4064,4.97792 C17.0765,9.06841 15.1143,13.7383 11.0239,15.4083 C8.66289,16.3723 6.10818,16.1252 4.05194,14.9602 C3.57143,14.6879 3.40261,14.0776 3.67487,13.5971 C3.94713,13.1166 4.55737,12.9478 5.03789,13.2201 C6.58414,14.0962 8.49796,14.2793 10.2679,13.5567 C13.3357,12.3042 14.8073,8.80177 13.5548,5.73391 C13.346,5.2226 13.5913,4.63886 14.1026,4.43011 Z M2.19544,9.52389 C2.26141,9.77396 2.3444,10.023 2.44513,10.2698 C2.54587,10.5165 2.66092,10.7525 2.78882,10.9773 C3.06197,11.4573 2.89426,12.0678 2.41425,12.341 C1.93424,12.6141 1.32368,12.4464 1.05054,11.9664 C0.879967,11.6666 0.727034,11.3528 0.593515,11.0257 C0.459996,10.6987 0.349566,10.3675 0.261594,10.034 C0.120724,9.50001 0.439434,8.9529 0.973451,8.81203 C1.50747,8.67116 2.05457,8.98987 2.19544,9.52389 Z M2.45915,3.60703 C2.93624,3.88526 3.09744,4.49756 2.81922,4.97464 C2.55491,5.42786 2.35056,5.91419 2.21184,6.42018 C2.06582,6.95281 1.51566,7.26622 0.983026,7.12019 C0.450396,6.97416 0.136992,6.424 0.283019,5.89137 C0.467702,5.21774 0.739666,4.57047 1.09154,3.96709 C1.36977,3.49001 1.98207,3.3288 2.45915,3.60703 Z M10.1104,0.28485 C10.7841,0.469533 11.4313,0.741497 12.0347,1.09338 C12.5118,1.3716 12.673,1.9839 12.3948,2.46098 C12.1166,2.93807 11.5043,3.09927 11.0272,2.82105 C10.574,2.55674 10.0876,2.3524 9.58163,2.21367 C9.049,2.06765 8.7356,1.51749 8.88162,0.984857 C9.02765,0.452227 9.57781,0.138823 10.1104,0.28485 Z M7.18978,0.975282 C7.33065,1.5093 7.01194,2.0564 6.47792,2.19727 C6.22785,2.26324 5.97878,2.34623 5.73205,2.44696 C5.48531,2.5477 5.24933,2.66275 5.02455,2.79066 C4.54454,3.0638 3.93398,2.89609 3.66084,2.41608 C3.3877,1.93607 3.55541,1.32551 4.03542,1.05237 C4.33519,0.881798 4.64904,0.728865 4.97607,0.595346 C5.30309,0.461827 5.6343,0.351397 5.96779,0.263425 C6.50181,0.122556 7.04891,0.441265 7.18978,0.975282 Z"/>
    </svg>
    <div
    :style="optionsLabel"
    :class="{ active: !active }"
    class="label"
    v-if="textShow"
    >
      {{ text }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'StatusIcon',
  props: {
    text: {
      type: String,
      default: ''
    },
    optionsLabel: {
      type: Object,
      default: () => ({})
    },
    active: {
      type: Boolean,
      default: false
    },
    options: {
      type: Object,
      default: () => ({ width: '15px', height: '15px' })
    }
  },
  data () {
    return {
      textShow: false
    }
  }
}
</script>

<style scoped>
.StatusIcon {
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
}

.label {
  background-color: #FFFFFF;
  border: thin solid #CCCCCC;
  border-radius: 5px;
  position: absolute;
  padding: 0.5%;
}

.active {
  display: none;
}
</style>
