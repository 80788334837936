<template>
  <div class='StoreIcon'>
    <svg width="20px" height="20px" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg" stroke-width="3" stroke="#000000" fill="none">
      <path d="M52,27.18V52.76a2.92,2.92,0,0,1-3,2.84H15a2.92,2.92,0,0,1-3-2.84V27.17"/>
      <polyline points="26.26 55.52 26.26 38.45 37.84 38.45 37.84 55.52"/>
      <path d="M8.44,19.18s-1.1,7.76,6.45,8.94a7.17,7.17,0,0,0,6.1-2A7.43,7.43,0,0,0,32,26a7.4,7.4,0,0,0,5,2.49,11.82,11.82,0,0,0,5.9-2.15,6.66,6.66,0,0,0,4.67,2.15,8,8,0,0,0,7.93-9.3L50.78,9.05a1,1,0,0,0-.94-.65H14a1,1,0,0,0-.94.66Z"/>
      <line x1="8.44" y1="19.18" x2="55.54" y2="19.18"/>
      <line x1="21.04" y1="19.18" x2="21.04" y2="8.4"/>
      <line x1="32.05" y1="19.18" x2="32.05" y2="8.4"/>
      <line x1="43.01" y1="19.18" x2="43.01" y2="8.4"/>
    </svg>
  </div>
</template>

<script>
export default {
  name: 'StoreIcon'
}
</script>

<style scoped>
.StoreIcon {
}
</style>
