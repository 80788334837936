<template>
  <div class='EnbFooter01'>
    ENB Business © 2022 | Todos os direitos reservados
  </div>
</template>

<script>
export default {
  name: 'EnbFooter01'
}
</script>

<style scoped>
.EnbFooter01 {
  width: 100%;
  height: 10%;
  display: flex;
  justify-content: center;
  align-items: end;
  padding-bottom: 2%;
  background-color: #333333;
  color: #FFFFFF;
  font-size: 12px;
}
</style>
