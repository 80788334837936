<template>
  <div class='CloseIcon'>
    <svg width="30px" height="30px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_429_11083)">
        <path d="M7 7.00006L17 17.0001M7 17.0001L17 7.00006" :stroke="color" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
      </g>
      <defs>
        <clipPath id="clip0_429_11083">
          <rect width="24" height="24" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  </div>
</template>

<script>
export default {
  name: 'CloseIcon',
  props: ['color']
}
</script>

<style scoped>
.CloseIcon {
  cursor: pointer;
}
</style>
