<template>
  <div class='CronoIcon'>
    <svg fill="#CCCCCC" width="50px" height="50px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M19 4h-2V2h-2v2H9V2H7v2H5c-1.103 0-2 .897-2 2v14c0 1.103.897 2 2 2h14c1.103 0 2-.897 2-2V6c0-1.103-.897-2-2-2zm.002 16H5V8h14l.002 12z"/><path d="m11 17.414 5.707-5.707-1.414-1.414L11 14.586l-2.293-2.293-1.414 1.414z"/>
    </svg>
  </div>
</template>

<script>
export default {
  name: 'CronoIcon'
}
</script>

<style scoped>
.CronoIcon {
  cursor: pointer;
  margin-top: 19px;
}
</style>
