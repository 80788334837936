<template>
  <div class='ProdBtn02'>
    <bag-icon/>
  </div>
</template>

<script>
import BagIcon from '../../Icons/BagIcon'
export default {
  name: 'ProdBtn02',
  components: { BagIcon }
}
</script>

<style scoped>
.ProdBtn02 {
  width: 50%;
  display: flex;
  justify-content: end;
}
</style>
