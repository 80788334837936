<template>
  <div class='SearchResult01'>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'SearchResult01'
}
</script>

<style scoped>
.SearchResult01 {
  width: 100%;
  height: 90%;
}
</style>
