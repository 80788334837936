export default {
  Clients: [],
  ClientView: {},
  PedidoView: {},
  SelectedClientEdit: {
    usuario: '',
    cpf: '',
    email: '',
    nome: '',
    sobrenome: '',
    celular: '',
    cep: '',
    bairro: '',
    endereco: '',
    numero: '',
    cidade: '',
    uf: '',
    senha: ''
  },
  Pedidos: []
}
